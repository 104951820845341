import axios, { AxiosInstance, AxiosRequestConfig} from 'axios';
import { ElMessage } from 'element-plus';
import Base64 from './base64'

function _encode() {
    const token = window.localStorage.getItem('token') ? window.localStorage.getItem('token') : ''
    const base64 = new Base64()
    const result = base64.encode(token + ':')
    return 'Basic ' + result;
}

class HttpRequset {
    private readonly baseUrl: string;
    
    constructor() {
        this.baseUrl = window.location.origin;
    }

    getInsideConfig() {
        const config = {
            baseUrl: this.baseUrl,
            headers: {
                Authorization: 'Bearer '
            },
            withCredentials: true
        }
        return config
    }

    interceptors(instance: AxiosInstance, url: string | number | undefined) {
        instance.interceptors.request.use((config: any) => {
            if (config.headers) {
                const isToken = (config.headers || {}).isToken === false;
                // const token = window.localStorage.getItem('access_token') ? window.localStorage.getItem('access_token') : '';
                if (!isToken) {
                    const encodeToken = _encode();
                    config.headers['Authorization'] = encodeToken// token
                }
                // if (token && !isToken) {
                //     config.headers['Authorization'] = 'Bearer ' + token;
                // } else {
                //     config.headers['Authorization'] = 'Bearer ';
                // }
                if (url === '/system/project/uploadImage') {
                    config.headers['Content-Type'] = 'multipart/form-data'
                }
            }
            return config;
        }, (error: any) => {
            return Promise.reject(error);
        });
        instance.interceptors.response.use((res: any) => {
            const { data } = res;
            const status = Number(res.status) || 200;
            if (status === 401) {
                ElMessage({
                    showClose: true,
                    message: '登录失效，请重新登陆',
                    type: 'error',
                });
                if (window.location.href.indexOf('login') === -1) {
                    window.localStorage.clear();      
                    window.location.href = `${window.location.origin}/violin-manage/login`
                }
            }
            return data;
        }, (error: any) => {
            const response = error.response;
            if (response) {
                ElMessage({
                    message: response.data.msg?response.data.msg:'请求报错',
                    type: 'error',
                    showClose: true
                })
                // if (window.location.href.indexOf('login') === -1) {
                //     window.localStorage.clear();      
                //     window.location.href = `${window.location.origin}/violin-manage/login`
                // }
            } else {
                if (error.message.includes('401') || error.message.includes('403') || error.message.includes('403')) {
                    ElMessage({
                        message: '登陆失效，请重新登陆',
                        type: 'error',
                        showClose: true
                    })
                    if (window.location.href.indexOf('login') === -1) {
                        window.localStorage.clear();      
                        window.location.href = `${window.location.origin}/violin-manage/login`
                    }
                    
                }
            }
            
            return Promise.reject(error);
        })
    }

    request(options: AxiosRequestConfig) {
        const instance = axios.create();
        options = Object.assign(this.getInsideConfig(), options)
        this.interceptors(instance, options.url)
        return instance(options);
    }
}

const http = new HttpRequset();

export default function request(options: AxiosRequestConfig) {
    const instance = axios.create();
    options = Object.assign(http.getInsideConfig(), options)
    http.interceptors(instance, options.url)
    return instance(options);
}