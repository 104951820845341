
import { defineComponent, reactive, toRefs, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from "vue-router";
import { Fold, ArrowDown, Close } from '@element-plus/icons-vue';

import { menuList } from '../utils/menus'

import * as R from 'ramda';

export default defineComponent({
    name: 'LayoutView',
    components: {
        Fold,
        // House,
        ArrowDown,
        Close
    },
    setup() {
        const store = useStore()
        const router = useRouter();
        const route = useRoute()
    
        const state = reactive({
            leftMenuIndex: '',
            menuTitle: '设备',
            leftMenu: [],
            rightMenu: [],
            menuList: [],
            activeIndex: '',
            userName: '未登录',
            showPwdDialog: false,
            activeRowName: '',
            nowPageTitle: '',
            topTadMenu: [] as Array<any>,
            hideOneClose: false
        });

        const initMenus = () => {
            state.menuList = Object.assign(menuList);
            if (Array.isArray(state.menuList)) {
                state.menuList.sort(function(a: any, b: any) {
                    return a.id - b.id;
                })
                let leftMenu: any[] = [];
                let rightMenu: any[] = [];
                let con = R.propEq('component', route.path);
                if (state.menuList.length > 0) {
                    state.menuList.forEach((item: any, idx) => {
                        let result: any = R.find(con)(item.children)
                        if (result) {
                            state.leftMenuIndex = (state.menuList[idx] as any).id;
                            state.menuTitle =  (state.menuList[idx] as any).label;
                            rightMenu = item.children;
                            state.activeIndex = route.path;
                            // 处理横向tab
                            state.activeRowName = route.path;
                            let topMenu: any = state.topTadMenu;
                            const menu: any = R.find(R.propEq('component', route.path))(rightMenu);
                            state.nowPageTitle = menu.label;
                            const tapIndex = R.findIndex(R.propEq('component', route.path))(topMenu);
                            if (tapIndex === -1 && menu != undefined) {
                                topMenu.push(menu);
                                state.topTadMenu = topMenu;
                            }
                        }
                        leftMenu.push({
                            id: item.id,
                            name: item.name,
                            icon: item.icon,
                            children: item.children
                        })
                    });
                }
                state.leftMenu = Object.assign(leftMenu);
                state.rightMenu = Object.assign(rightMenu);
            }
        }

        const handleSelect = (key: string, keyPath: string) => {
            state.activeIndex = key;
            router.push({ path: key});
        }
        const handleClickMenu = (e: any) => {
            // let topMenu: any = state.topTadMenu;
            state.activeRowName = e.index;
            if (Array.isArray(state.rightMenu)) {
                // const menu: any = R.find(R.propEq('component', e.index))(state.rightMenu);
                // const index = R.findIndex(R.propEq('component', e.index))(topMenu);
                // if (index === -1 && menu != undefined) {
                //     topMenu.push(menu);
                //     state.topTadMenu = topMenu;
                //     state.activeRowName = e.index;
                // } else {
                //     state.activeRowName = e.index;
                // }
                // if (topMenu.length === 1) {
                //     state.hideOneClose = true
                // } 
            }
        }

        const handleTabClick = (e: any) => {
            const index: number = e.index;
            state.rightMenu = state.menuList[index]['children'];
            state.menuTitle = state.menuList[index]['label'];
            const path = state.menuList[index]['children'][0]['component']
            state.activeIndex = path;
            const activeIndex = state.menuList[index]['id'];
            state.leftMenuIndex = activeIndex;
            window.localStorage.setItem("activeIndex", activeIndex);
            if (path) {
                router.push({ path: path});
            }
        }
        
        const logouFromSys = () => {
            window.localStorage.setItem('token', '');
            router.push({ path: '/login' });
        }

        const updatePassword = () => {
            state.showPwdDialog = true;
        }

        const cancelPwdDialog = () => {
            state.showPwdDialog = false;
        }

        const handleRowTabClick = (e: any) => {
            state.activeIndex = e.paneName;
            router.push({ path: e.paneName});
            
        }

        const removeTheTab = (paneName: any) => {
            if (Array.isArray(state.topTadMenu)) {
                const index = R.findIndex(R.propEq('component', paneName))(state.topTadMenu);
                state.topTadMenu = R.remove(index, 1)(state.topTadMenu);
                if (state.activeRowName === paneName) {
                    if (index > 0) {
                        const path: any = state.topTadMenu[index-1]['component'];
                        if (path) {
                            state.activeIndex = path;
                            state.activeRowName = path
                            router.push({ path: path}); 
                        }
                    } else {
                        const path: any = state.topTadMenu[0]['component'];
                        if (path) {
                            state.activeIndex = path;
                            state.activeRowName = path
                            router.push({ path: path}); 
                        }
                    }
                }
            }
        }

        router.beforeEach((to, form, next) => {
            const path: any = to.path;
            // 处理横向tab
            let rightMenu: any[] = [];
            let con = R.propEq('component', path);
            if (Array.isArray(state.menuList)) {
                if (state.menuList.length > 0) {
                    state.menuList.forEach((item: any, idx) => {
                        let result: any = R.find(con)(item.children);
                        if (result) {
                            rightMenu = item.children;
                            // 处理横向tab
                            state.activeRowName = path;
                            let topMenu: any = state.topTadMenu;
                            const menu: any = R.find(R.propEq('component', path))(rightMenu);
                            state.nowPageTitle = menu.label;
                            const tapIndex = R.findIndex(R.propEq('component', path))(topMenu);
                            if (tapIndex === -1 && menu != undefined && menu != 'undefined') {
                                topMenu.push(menu);
                                state.topTadMenu = topMenu;
                            }
                            state.leftMenuIndex = item.id;
                        }
                    });
                    if (R.findIndex(R.propEq('component', path))(state.rightMenu) === -1) {
                        if (rightMenu && rightMenu.length > 0) {
                            state.rightMenu = Object.assign(rightMenu);
                        }
                        
                    }
                }
            }
            next();
        });

        return {
            ...toRefs(state),
            tabPosition: ref('left'),
            handleSelect,
            handleClickMenu,
            handleTabClick,
            initMenus,
            logouFromSys,
            updatePassword,
            cancelPwdDialog,
            handleRowTabClick,
            removeTheTab
        }
    },
    created() {
        this.initMenus();
    },

})
