import request from '../utils/axios'

export interface loginParam {
    username: string,
    password: string
}

export const loginByUsername = (param: loginParam) => {
    return request({
        url: `/violinCms/user/login`,
        method: 'POST',
        data: param
    })
}