
import { loginByUsername } from '@/apis/login'

import store from '../../store'

const user = {
    namespaced: true,
    state: {
        userInfo: localStorage.getItem('userInfo'),
        access_token: '',
        // refresh_token: '',
        // expires_in: '',
        permissions: localStorage.getItem('permissions') || [],
        roles: localStorage.getItem('roles') || [],
        menus: localStorage.getItem('menus') || []
    },
    actions: {
        loginToChanger(context: any, param: any) {
            console.log(param)
            return loginByUsername(param.param).then((res:any) => {
                console.log(res)
                if (res) {
                    window.localStorage.setItem('token', res.data);
                    context.commit('SET_ACCESS_TOKEN', res.data);
                }
                return res;
            })
        },
        FedLogOut(context: any): any {
            const token = store.getters.access_token;
            window.localStorage.setItem('token', '');
            // window.localStorage.setItem('menus', '');          
            // window.localStorage.setItem('userInfo', '');      
            // window.localStorage.setItem('permissions', '');            
            // window.localStorage.setItem('roles', '');
            window.localStorage.clear();      
            context.commit('SET_ACCESS_TOKEN', '');
            // context.commit('SET_PERMISSIONS', []);
            context.commit('SET_USER_INFO', {});
            context.commit('SET_MENUS', '');
        }
    },
    mutations: {
        SET_ACCESS_TOKEN: (state: any, access_token: string) => {
            state.access_token = access_token;
        },
        SET_USER_INFO: (state: any, userInfo: any) => {
            localStorage.setItem('userInfo', JSON.stringify(userInfo));
            state.userInfo = JSON.stringify(userInfo);
        },
        SET_PERMISSIONS: (state: any, permissions: any) => {
            localStorage.setItem('permissions', permissions);
            state.permissions = permissions;
        },
        SET_ROLES: (state: any, roles: any) => {
            localStorage.setItem('roles', roles);
            state.roles = roles;
        },
        SET_MENUS: (state: any, menus: any) => {
            localStorage.setItem('menus', JSON.stringify(menus));
            state.menus = JSON.stringify(menus);
        }
    }
}

export default user;