export const menuList = [
    {
        id: 2000,
        parentId: -1,
        name: '比赛',
        icon: 'violin-match',
        type: 0,
        sort: 2,
        label: '比赛',
        path: '',
        component: '',
        children: [
            {
                id: 2400,
                parentId: 2000,
                name: '赛事管理',
                icon: 'violin-match-manage',
                type: 0,
                sort: 20,
                label: '赛事管理',
                path: '/matchMana', 
                component: '/matchMana',
                children: [] 
            },
            {
                id: 2100,
                parentId: 2000,
                name: '初赛报名管理',
                icon: 'violin-match-manage',
                type: 0,
                sort: 21,
                label: '初赛报名管理',
                path: '/entries', 
                component: '/entries',
                children: [] 
            }, {
                id: 2101,
                parentId: 2001,
                name: '复赛报名管理',
                icon: 'violin-match-manage',
                type: 0,
                sort: 21,
                label: '复赛报名管理',
                path: '/secondEntries', 
                component: '/secondEntries',
                children: [] 
            },{
                id: 2102,
                parentId: 2001,
                name: '决赛报名管理',
                icon: 'violin-match-manage',
                type: 0,
                sort: 21,
                label: '决赛报名管理',
                path: '/finalEntries', 
                component: '/finalEntries',
                children: [] 
            },
            {
                id: 2200,
                parentId: 2000,
                name: '分组管理',
                icon: 'violin-group-manage',
                type: 0,
                sort: 22,
                label: '分组管理',
                path: '/group', 
                component: '/group',
                children: [] 
            }, {
                id: 2300,
                parentId: 2000,
                name: '赛区管理',
                icon: 'violin-match',
                type: 0,
                sort: 23,
                label: '赛区管理',
                path: '/matchArea', 
                component: '/matchArea',
                children: [] 
            },
            {
                id: 2400,
                parentId: 2000,
                name: '负责人与评委管理',
                icon: 'violin-user-manage',
                type: 0,
                sort: 24,
                label: '负责人与评委管理',
                path: '/principal', 
                component: '/principal',
                children: [] 
            },
            {
                id: 2500,
                parentId: 2000,
                name: '决赛伴奏管理',
                icon: 'violin-user-manage',
                type: 0,
                sort: 25,
                label: '决赛伴奏管理',
                path: '/accompaniment', 
                component: '/accompaniment',
                children: [] 
            },
            {
                
                id: 2600,
                parentId: 2000,
                name: '参赛作品异常',
                icon: 'violin-user-manage',
                type: 0,
                sort: 25,
                label: '参赛作品异常',
                path: '/errEntries', 
                component: '/errEntries',
                children: [] 
            },
            {
                
                id: 2700,
                parentId: 2000,
                name: '评委大师课',
                icon: 'violin-master',
                type: 0,
                sort: 27,
                label: '评委大师课',
                path: '/matchTeacher', 
                component: '/matchTeacher',
                children: [] 
            }
        ]
    }, 
    {
        id: 3000,
        parentId: -1,
        name: '大师班',
        icon: 'violin-master-class',
        type: 0,
        sort: 3,
        label: '大师班',
        path: '',
        children: [
            {
                id: 3001,
                parentId: 3000,
                name: '大师班管理',
                icon: 'violin-match-manage',
                type: 0,
                sort: 30,
                label: '大师班管理',
                path: '/masterMana', 
                component: '/masterMana',
                children: [] 
            },
            {
                id: 3100,
                parentId: 3000,
                name: '课程管理',
                icon: 'violin-master-price',
                type: 0,
                sort: 31,
                label: '课程管理',
                path: '/classstu', 
                component: '/classstu',
                children: [] 
            }, {
                id: 3200,
                parentId: 3000,
                name: '食宿管理',
                icon: 'violin-master-bed',
                type: 0,
                sort: 32,
                label: '食宿管理',
                path: '/bedInfo', 
                component: '/bedInfo',
                children: [] 
            }, {
                id: 3300,
                parentId: 3000,
                name: '教师管理',
                icon: 'violin-master-teacher',
                type: 0,
                sort: 33,
                label: '大师班教师',
                path: '/teacher', 
                component: '/teacher',
                children: [] 
            }, {
                id: 3400,
                parentId: 3000,
                name: '报名信息',
                icon: 'violin-master-student',
                type: 0,
                sort: 34,
                label: '报名信息',
                path: '/stuInfo', 
                component: '/stuInfo',
                children: [] 
            }
        ]
    }, 
    {
        id: 1000,
        parentId: -1,
        name: '项目',
        icon: 'violin-setting',
        type: 0,
        sort: 3,
        label: '项目',
        path: '',
        component: '',
        children: [
            // {
            //     id: 1100,
            //     parentId: 1000,
            //     name: '项目管理',
            //     icon: 'violin-match-manage',
            //     type: 0,
            //     sort: 11,
            //     label: '项目管理',
            //     path: '/match', 
            //     component: '/match',
            //     children: []
            // }, 
            {
                id: 1200,
                parentId: 1000,
                name: 'Banner管理',
                icon: 'violin-banner-manage',
                type: 0,
                sort: 12,
                label: 'Banner管理',
                path: '/banner', 
                component: '/banner',
                children: []
            }, {
                id: 1300,
                parentId: 1000,
                name: '往期回顾管理',
                icon: 'violin-history-manage',
                type: 0,
                sort: 13,
                label: '往期回顾管理',
                path: '/review', 
                component: '/review',
                children: []
            }, {
                id: 1300,
                parentId: 1000,
                name: '消息通知管理',
                icon: 'violinxiaoxizhongxin',
                type: 0,
                sort: 13,
                label: '消息通知管理',
                path: '/announcement', 
                component: '/announcement',
                children: []
            }
            // announcement
        ]
    },
    {
        id: 4000,
        parentId: -1,
        name: '订单',
        icon: 'violin-order',
        type: 0,
        sort: 4,
        label: '订单',
        path: '',
        children: [
            {
                id: 4100,
                parentId: 4000,
                name: '订单管理',
                icon: 'violin-order-manage',
                type: 0,
                sort: 41,
                label: '订单管理',
                path: '/orderList', 
                component: '/orderList',
                children: [] 
            },
            {
                id: 4200,
                parentId: 4000,
                name: '退款申请',
                icon: 'violin-order-manage',
                type: 0,
                sort: 42,
                label: '退款申请',
                path: '/refundOrder', 
                component: '/refundOrder',
                children: [] 
            }
        ]
    }, {
        id: 5000,
        parentId: -1,
        name: '联盟',
        icon: 'violin-violin',
        type: 0,
        sort: 5,
        label: '联盟',
        path: '',
        children: [
            {
                id: 5100,
                parentId: 5000,
                name: '联盟教师',
                icon: 'violin-teacher-union',
                type: 0,
                sort: 51,
                label: '联盟教师',
                path: '/unionTeacher', 
                component: '/unionTeacher',
                children: [] 
            }
        ]
    }, {
        id: 6000,
        parentId: -1,
        name: '用户',
        icon: 'violin-user',
        type: 0,
        sort: 6,
        label: '联盟',
        path: '',
        children: [
            {
                id: 6100,
                parentId: 6000,
                name: '小程序用户',
                icon: 'violin-user-mini',
                type: 0,
                sort: 61,
                label: '小程序用户',
                path: '/miniUser', 
                component: '/miniUser',
                children: [] 
            }
        ]
    }
]