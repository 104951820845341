import { createApp } from "vue";
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import App from "./App.vue";
import router from "./router";
import store from "./store";
// import Common from '@/utils/common'
import './assets/iconfont/iconfont.css';
// import './assets/icons/iconfont/iconfont.js';
import './plugins';

const app = createApp(App);

app.use(store)

app.use(router)
// app.use(Common)
app.use(ElementPlus, {
    size: 'default',
    locale: zhCn
})

app.mount('#app')
