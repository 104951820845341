import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LayoutView from "../layouts/index.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/home",
        name: "home",
        component: HomeView,
    },
    {
        path: "/about",
        name: "about",
        component: () =>import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    },
    {
        path: "/login",
        name: "login",
        component: () =>import(/* webpackChunkName: "about" */ "../views/login/index.vue"),
    },
    {
        path: '/',
        component: LayoutView,
        redirect: '/matchMana',
        children: [
            // {
            //     path: '/match',
            //     name: 'match',
            //     component: () => import('../views/Project/matchManage.vue'),
            //     meta: { name: '项目管理', code: '' }
            // },
            {
                path: '/bedInfo',
                name: 'bedInfo',
                component: () => import('../views/MasterClass/bedManage.vue'),
                meta: { name: '食宿管理', code: '' }
            },
            {
                path: '/classstu',
                name: 'classstu',
                component: () => import('../views/MasterClass/classManage.vue'),
                meta: { name: '课程管理', code: '' }
            },
            {
                path: '/teacher',
                name: 'teacher',
                component: () => import('../views/MasterClass/teacher.vue'),
                meta: { name: '大师班教师管理', code: '' }
            }, {
                path: '/stuInfo',
                name: 'stuInfo',
                component: () => import('../views/MasterClass/stuLIst.vue'),
                meta: { name: '报名选手管理', code: '' }
            }, {
                
                path: '/entries',
                name: 'entries',
                component: () => import('../views/Match/userEntries.vue'),
                meta: { name: '参赛作品管理', code: '' }
            },{
                path: '/group',
                name: 'group',
                component: () => import('../views/Match/group.vue'),
                meta: { name: '分组管理', code: '' }
            }, {
                
                path: '/entriesUser',
                name: 'entriesUser',
                component: () => import('../views/Match/contestant.vue'),
                meta: { name: '参赛选手管理', code: '' }
            }, { 
                path: '/orderList',
                name: 'orderList',
                component: () => import('../views/Order/order.vue'),
                meta: { name: '订单管理', code: '' }
            }, { 
                path: '/unionTeacher',
                name: 'unionTeacher',
                component: () => import('../views/Union/unionTeacher.vue'),
                meta: { name: '教师联盟', code: '' }
            }, { 
                path: '/miniUser',
                name: 'miniUser',
                component: () => import('../views/User/miniUser.vue'),
                meta: { name: '小程序用户', code: '' }
            }, { 
                path: '/banner',
                name: 'banner',
                component: () => import('../views/Banner/index.vue'),
                meta: { name: 'Banner管理', code: '' }
            },{ 
                path: '/review',
                name: 'review',
                component: () => import('../views/PreviousReview/index.vue'),
                meta: { name: '往期回顾管理', code: '' }
            }, {
                path: '/announcement',
                name: 'announcement',
                component: () => import('../views/Announcement/index.vue'),
                meta: { name: '往期回顾管理', code: '' }
            }, {
                path: '/refundOrder',
                name: 'refundOrder',
                component: () => import('../views/RefundOrder/index.vue'),
                meta: { name: '申请退款管理', code: '' }
            }, {
                path: '/matchArea',
                name: 'matchArea',
                component: () => import('../views/Match/areaManage.vue'),
                meta: { name: '赛区管理', code: '' }
            },
            {
                path: '/noPermission',
                name: 'noPermission',
                component: () => import('../views/NoPermission/index.vue')
            },
            {
                path: '/:pathMatch(.*)',
                component: () => import('../views/404/index.vue')
            },
            {
                path: '/matchMana',
                name: 'matchMana',
                component: () => import('../views/Match/matchMana.vue')
            },{
                path: '/secondEntries',
                name: 'secondEntries',
                component: () => import('../views/Match/secondEntries.vue')
            }
            ,{
                path: '/finalEntries',
                name: 'finalEntries',
                component: () => import('../views/Match/finalEntries.vue')
            },
            {
                path: '/masterMana',
                name: 'masterMana',
                component: () => import('../views/MasterClass/masterMana.vue')
            },
            {
                path: '/principal',
                name: 'principal',
                component: () => import('../views/Match/principalMana.vue')
            },
            {
                path: '/accompaniment',
                name: 'accompaniment',
                component: () => import('../views/Match/accompanimentMana.vue')
            },
            {
                path: '/errEntries',
                name: 'errEntries',
                component: () => import('../views/Match/ErrorEntries.vue')
            },
            {
                path: '/matchTeacher',
                name: 'matchTeacher',
                component: () => import('../views/MatchTeacher/matchTeacherList.vue')
            }
        ]
    }
];

// process.env.BASE_URL

const router = createRouter({
    history: createWebHistory('/violin-manage'),
    routes,
});

export default router;
